/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './Short.module.css';
import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const ShortBanner = ({ data }) =>     {
    if (!Object.keys(data || {})?.length) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Standard/Short/Short.js',
            message: 'No entries provided for ShortBanner',
        });
        return <></>;
    }
    useStyles(styles);
    const handleClick = universalHandleClick();
    const backgrounds = {
        desktop: data.background_image?.desktop?.url,
        tablet: data.background_image?.tablet?.url, // can add fallbacks easily this way
        mobile: data.background_image?.mobile?.url,
    };
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    const imageQuality = '?auto=webp';
    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgrounds[device(matches)]}${imageQuality})` }} className={styles.backgroundImageContainer}>
                {data?.message && <MessageStandard data={data?.message} fullBackground bannerType="shortBanner" />}
                <picture>
                    <source srcSet={`${backgrounds[device(matches)]}${imageQuality}`} />
                    {backgrounds[device(matches)] && <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" />}
                </picture>
            </div>

        </>
    );

    return (
        <div data-testid="shortBanner" role="presentation" onClick={() => handleClick(data.message.cta)} className={styles.shortBannerContainer}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};
ShortBanner.propTypes = {
    data: object.isRequired,
};
export default ShortBanner;
